// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$menu-text-1-name: 'menu-text-1';
$menu-text-1-x: 0px;
$menu-text-1-y: 568px;
$menu-text-1-offset-x: 0px;
$menu-text-1-offset-y: -568px;
$menu-text-1-width: 244px;
$menu-text-1-height: 40px;
$menu-text-1-total-width: 244px;
$menu-text-1-total-height: 858px;
$menu-text-1-image: '/common/img/sprite-menu.png';
$menu-text-1: (0px, 568px, 0px, -568px, 244px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-1', );
$menu-text-2-name: 'menu-text-2';
$menu-text-2-x: 0px;
$menu-text-2-y: 368px;
$menu-text-2-offset-x: 0px;
$menu-text-2-offset-y: -368px;
$menu-text-2-width: 154px;
$menu-text-2-height: 40px;
$menu-text-2-total-width: 244px;
$menu-text-2-total-height: 858px;
$menu-text-2-image: '/common/img/sprite-menu.png';
$menu-text-2: (0px, 368px, 0px, -368px, 154px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-2', );
$menu-text-3-name: 'menu-text-3';
$menu-text-3-x: 0px;
$menu-text-3-y: 618px;
$menu-text-3-offset-x: 0px;
$menu-text-3-offset-y: -618px;
$menu-text-3-width: 178px;
$menu-text-3-height: 40px;
$menu-text-3-total-width: 244px;
$menu-text-3-total-height: 858px;
$menu-text-3-image: '/common/img/sprite-menu.png';
$menu-text-3: (0px, 618px, 0px, -618px, 178px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-3', );
$menu-text-4-name: 'menu-text-4';
$menu-text-4-x: 0px;
$menu-text-4-y: 668px;
$menu-text-4-offset-x: 0px;
$menu-text-4-offset-y: -668px;
$menu-text-4-width: 142px;
$menu-text-4-height: 40px;
$menu-text-4-total-width: 244px;
$menu-text-4-total-height: 858px;
$menu-text-4-image: '/common/img/sprite-menu.png';
$menu-text-4: (0px, 668px, 0px, -668px, 142px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-4', );
$menu-text-5-name: 'menu-text-5';
$menu-text-5-x: 0px;
$menu-text-5-y: 718px;
$menu-text-5-offset-x: 0px;
$menu-text-5-offset-y: -718px;
$menu-text-5-width: 216px;
$menu-text-5-height: 40px;
$menu-text-5-total-width: 244px;
$menu-text-5-total-height: 858px;
$menu-text-5-image: '/common/img/sprite-menu.png';
$menu-text-5: (0px, 718px, 0px, -718px, 216px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-5', );
$menu-text-6-name: 'menu-text-6';
$menu-text-6-x: 0px;
$menu-text-6-y: 768px;
$menu-text-6-offset-x: 0px;
$menu-text-6-offset-y: -768px;
$menu-text-6-width: 200px;
$menu-text-6-height: 40px;
$menu-text-6-total-width: 244px;
$menu-text-6-total-height: 858px;
$menu-text-6-image: '/common/img/sprite-menu.png';
$menu-text-6: (0px, 768px, 0px, -768px, 200px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-6', );
$menu-text-home-hover-name: 'menu-text-home-hover';
$menu-text-home-hover-x: 0px;
$menu-text-home-hover-y: 168px;
$menu-text-home-hover-offset-x: 0px;
$menu-text-home-hover-offset-y: -168px;
$menu-text-home-hover-width: 130px;
$menu-text-home-hover-height: 40px;
$menu-text-home-hover-total-width: 244px;
$menu-text-home-hover-total-height: 858px;
$menu-text-home-hover-image: '/common/img/sprite-menu.png';
$menu-text-home-hover: (0px, 168px, 0px, -168px, 130px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-home-hover', );
$menu-text-home-name: 'menu-text-home';
$menu-text-home-x: 0px;
$menu-text-home-y: 218px;
$menu-text-home-offset-x: 0px;
$menu-text-home-offset-y: -218px;
$menu-text-home-width: 130px;
$menu-text-home-height: 40px;
$menu-text-home-total-width: 244px;
$menu-text-home-total-height: 858px;
$menu-text-home-image: '/common/img/sprite-menu.png';
$menu-text-home: (0px, 218px, 0px, -218px, 130px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-home', );
$menu-text-hover-1-name: 'menu-text-hover-1';
$menu-text-hover-1-x: 0px;
$menu-text-hover-1-y: 268px;
$menu-text-hover-1-offset-x: 0px;
$menu-text-hover-1-offset-y: -268px;
$menu-text-hover-1-width: 244px;
$menu-text-hover-1-height: 40px;
$menu-text-hover-1-total-width: 244px;
$menu-text-hover-1-total-height: 858px;
$menu-text-hover-1-image: '/common/img/sprite-menu.png';
$menu-text-hover-1: (0px, 268px, 0px, -268px, 244px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-hover-1', );
$menu-text-hover-2-name: 'menu-text-hover-2';
$menu-text-hover-2-x: 0px;
$menu-text-hover-2-y: 318px;
$menu-text-hover-2-offset-x: 0px;
$menu-text-hover-2-offset-y: -318px;
$menu-text-hover-2-width: 154px;
$menu-text-hover-2-height: 40px;
$menu-text-hover-2-total-width: 244px;
$menu-text-hover-2-total-height: 858px;
$menu-text-hover-2-image: '/common/img/sprite-menu.png';
$menu-text-hover-2: (0px, 318px, 0px, -318px, 154px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-hover-2', );
$menu-text-hover-3-name: 'menu-text-hover-3';
$menu-text-hover-3-x: 0px;
$menu-text-hover-3-y: 818px;
$menu-text-hover-3-offset-x: 0px;
$menu-text-hover-3-offset-y: -818px;
$menu-text-hover-3-width: 178px;
$menu-text-hover-3-height: 40px;
$menu-text-hover-3-total-width: 244px;
$menu-text-hover-3-total-height: 858px;
$menu-text-hover-3-image: '/common/img/sprite-menu.png';
$menu-text-hover-3: (0px, 818px, 0px, -818px, 178px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-hover-3', );
$menu-text-hover-4-name: 'menu-text-hover-4';
$menu-text-hover-4-x: 0px;
$menu-text-hover-4-y: 418px;
$menu-text-hover-4-offset-x: 0px;
$menu-text-hover-4-offset-y: -418px;
$menu-text-hover-4-width: 142px;
$menu-text-hover-4-height: 40px;
$menu-text-hover-4-total-width: 244px;
$menu-text-hover-4-total-height: 858px;
$menu-text-hover-4-image: '/common/img/sprite-menu.png';
$menu-text-hover-4: (0px, 418px, 0px, -418px, 142px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-hover-4', );
$menu-text-hover-5-name: 'menu-text-hover-5';
$menu-text-hover-5-x: 0px;
$menu-text-hover-5-y: 468px;
$menu-text-hover-5-offset-x: 0px;
$menu-text-hover-5-offset-y: -468px;
$menu-text-hover-5-width: 216px;
$menu-text-hover-5-height: 40px;
$menu-text-hover-5-total-width: 244px;
$menu-text-hover-5-total-height: 858px;
$menu-text-hover-5-image: '/common/img/sprite-menu.png';
$menu-text-hover-5: (0px, 468px, 0px, -468px, 216px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-hover-5', );
$menu-text-hover-6-name: 'menu-text-hover-6';
$menu-text-hover-6-x: 0px;
$menu-text-hover-6-y: 518px;
$menu-text-hover-6-offset-x: 0px;
$menu-text-hover-6-offset-y: -518px;
$menu-text-hover-6-width: 200px;
$menu-text-hover-6-height: 40px;
$menu-text-hover-6-total-width: 244px;
$menu-text-hover-6-total-height: 858px;
$menu-text-hover-6-image: '/common/img/sprite-menu.png';
$menu-text-hover-6: (0px, 518px, 0px, -518px, 200px, 40px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text-hover-6', );
$menu-text2-1-name: 'menu-text2-1';
$menu-text2-1-x: 0px;
$menu-text2-1-y: 28px;
$menu-text2-1-offset-x: 0px;
$menu-text2-1-offset-y: -28px;
$menu-text2-1-width: 74px;
$menu-text2-1-height: 18px;
$menu-text2-1-total-width: 244px;
$menu-text2-1-total-height: 858px;
$menu-text2-1-image: '/common/img/sprite-menu.png';
$menu-text2-1: (0px, 28px, 0px, -28px, 74px, 18px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text2-1', );
$menu-text2-2-name: 'menu-text2-2';
$menu-text2-2-x: 0px;
$menu-text2-2-y: 56px;
$menu-text2-2-offset-x: 0px;
$menu-text2-2-offset-y: -56px;
$menu-text2-2-width: 94px;
$menu-text2-2-height: 18px;
$menu-text2-2-total-width: 244px;
$menu-text2-2-total-height: 858px;
$menu-text2-2-image: '/common/img/sprite-menu.png';
$menu-text2-2: (0px, 56px, 0px, -56px, 94px, 18px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text2-2', );
$menu-text2-3-name: 'menu-text2-3';
$menu-text2-3-x: 0px;
$menu-text2-3-y: 84px;
$menu-text2-3-offset-x: 0px;
$menu-text2-3-offset-y: -84px;
$menu-text2-3-width: 170px;
$menu-text2-3-height: 18px;
$menu-text2-3-total-width: 244px;
$menu-text2-3-total-height: 858px;
$menu-text2-3-image: '/common/img/sprite-menu.png';
$menu-text2-3: (0px, 84px, 0px, -84px, 170px, 18px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text2-3', );
$menu-text2-4-name: 'menu-text2-4';
$menu-text2-4-x: 0px;
$menu-text2-4-y: 112px;
$menu-text2-4-offset-x: 0px;
$menu-text2-4-offset-y: -112px;
$menu-text2-4-width: 166px;
$menu-text2-4-height: 18px;
$menu-text2-4-total-width: 244px;
$menu-text2-4-total-height: 858px;
$menu-text2-4-image: '/common/img/sprite-menu.png';
$menu-text2-4: (0px, 112px, 0px, -112px, 166px, 18px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text2-4', );
$menu-text2-5-name: 'menu-text2-5';
$menu-text2-5-x: 0px;
$menu-text2-5-y: 140px;
$menu-text2-5-offset-x: 0px;
$menu-text2-5-offset-y: -140px;
$menu-text2-5-width: 86px;
$menu-text2-5-height: 18px;
$menu-text2-5-total-width: 244px;
$menu-text2-5-total-height: 858px;
$menu-text2-5-image: '/common/img/sprite-menu.png';
$menu-text2-5: (0px, 140px, 0px, -140px, 86px, 18px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text2-5', );
$menu-text2-6-name: 'menu-text2-6';
$menu-text2-6-x: 0px;
$menu-text2-6-y: 0px;
$menu-text2-6-offset-x: 0px;
$menu-text2-6-offset-y: 0px;
$menu-text2-6-width: 96px;
$menu-text2-6-height: 18px;
$menu-text2-6-total-width: 244px;
$menu-text2-6-total-height: 858px;
$menu-text2-6-image: '/common/img/sprite-menu.png';
$menu-text2-6: (0px, 0px, 0px, 0px, 96px, 18px, 244px, 858px, '/common/img/sprite-menu.png', 'menu-text2-6', );
$spritesheet-width: 244px;
$spritesheet-height: 858px;
$spritesheet-image: '/common/img/sprite-menu.png';
$spritesheet-sprites: ($menu-text-1, $menu-text-2, $menu-text-3, $menu-text-4, $menu-text-5, $menu-text-6, $menu-text-home-hover, $menu-text-home, $menu-text-hover-1, $menu-text-hover-2, $menu-text-hover-3, $menu-text-hover-4, $menu-text-hover-5, $menu-text-hover-6, $menu-text2-1, $menu-text2-2, $menu-text2-3, $menu-text2-4, $menu-text2-5, $menu-text2-6, );
$spritesheet: (244px, 858px, '/common/img/sprite-menu.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
