@charset "utf-8";
// @import '../sprite/icon';

// @import 'susy';
// $susy: (
// 	columns: 12,
// 	gutters: 1 / 4,
// 	math: magic,
// 	output: float,
// 	gutter-position: inside,
// );

@function palette($palette-group, $palette-type: 'default') {
	@return map-get(map-get($palettes, $palette-group), $palette-type);
}

$palettes: (
	'pink': (
		'default': #fff,
	),
	'yellow': (
		'default': #f7f9d3,
	),
	'red': (
		'default': #ff0000,
	),

	'blue': (
		'default': #fff,
	),
	'orange': (
		'default': #f15a24,
	),
	'green': (
		'default': #0f4c2d,
		'1': #ecef90,
		'2': #d9e021,
		'3': #bad991,
		'4': #6f9481,
		'5': #75b224,
	),
	'brown': (
		'default': #7b6454,
		'1': #684d3c,
		'2': #705322,
		'3': #42210b,
	),
	'gray': (
		'default': #e5e5e5,
	),

	'marker': (
		'default': #fff,
	),

	'color': (
		'default': #000,
		// 'default_bg': #fff,
		'placeholder': #808080,
		'breadcrumbs': #000,
		'outline': #48b7eb,
		'hover_bg': #2c2c96,
	),

	'link': (
		'default': #ff8b00,



		'hover': darken(#ff8b00, 5%),
		'mail_link': #0f65f4,
		'blue': #0f65f4,
		'black': #000,
	),
/*

*/
	'category': (
		'important': #f15a24,
	),

	'button': (
		'hover': #fff,
		'bg': #7f7f7f,
		'bg_hover': #000,
	),
	'table': (
		'border': #a09085,
		'bg': #f7f6f5,
		'bg2': #f2f8fc,
	),
	'border': (
		'default': #ccc,
		'1': #e5e5e5,
	),
	'background': (
		'default': #fdfdf4,
	)
);

$pc_header_height: 175px;
$sp_header_height: 51px;

$border_radius: 5px;
$image_radius: 10px;

$scroll_to_top_width: 70px;
$scroll_to_top_height: 70px;
$pc_padding: 30px;
$sp_padding: 10px;

$pc: "only screen and (min-width: 768px)";
$sp: "only screen and (max-width: 767px)";


$fontsize8: 0.600rem;
$fontsize9: 0.563rem;
$fontsize10: 0.625rem;
$fontsize11: 0.688rem;
$fontsize12: 0.750rem;
$fontsize13: 0.813rem;
$fontsize14: 0.875rem;
$fontsize15: 0.938rem;
$fontsize16: 1rem;
$fontsize17: 1.063rem;
$fontsize18: 1.125rem;
$fontsize19: 1.188rem;
$fontsize20: 1.250rem;
$fontsize21: 1.313rem;
$fontsize22: 1.375rem;
$fontsize23: 1.438rem;
$fontsize24: 1.500rem;
$fontsize25: 1.563rem;
$fontsize26: 1.625rem;
$fontsize27: 1.688rem;
$fontsize28: 1.750rem;
$fontsize29: 1.813rem;
$fontsize30: 1.875rem;
$fontsize31: 1.938rem;
$fontsize32: 2rem;
$fontsize33: 2.063rem;
$fontsize34: 2.125rem;
$fontsize35: 2.188rem;
$fontsize36: 2.250rem;

$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);

$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

@import 'mixins';
