@charset "utf-8";
@import 'import/var';
// @import 'sprite/common';
// @import 'sprite/menu';
@import 'sprite/menu';


/**************************
	drawer
*/

$drawer_width: 80%;
$drawer_li_top_height: 50px;
$drawer_li_height: 50px;
$drawer_a_padding_left: 25px;
$drawer_color: palette('green', 'default');
$drawer_color-hover: palette('orange', 'default');
$drawer_background-color: palette('background', 'default');
$drawer_li_border_color: palette('green', '2');
$drawer_li_border_width: 1px;
$drawer_arrow_color: palette('green', '4');
$drawer_arrow_width: 1px;

#drawer {
	visibility: hidden;
	z-index: 1000;
	width: $drawer_width;

	li {
		min-height: $drawer_li_height;
		box-sizing: border-box;
		&:first-child {
			min-height: $drawer_li_top_height;
			position: relative;
			top: 0;
			left: 0;

			.drawer__a {
				min-height: $drawer_li_top_height;

			}
		}
		&:not(.mm-divider)::after {
			border-bottom: $drawer_li_border_width solid $drawer_li_border_color;
		}
		.mm-next {
			height: $drawer_li_height - $drawer_li_border_width;
			box-sizing: border-box;
			width: 40px;
			&::after {
				right: 17px;
				border-color: $drawer_arrow_color;
				border-width: $drawer_arrow_width;
			}
			&::before {
				display: none;
			}
		}
	}
	a:not(.cancel) {
		@include on_opacity;
		color: $drawer_color;
		// font-weight: bold;
		background-color: $drawer_background-color;
		font-size: $fontsize16;
		// font-weight: normal;
		height: $drawer_li_height - $drawer_li_border_width;
		line-height: 1;
		box-sizing: border-box;
		margin-right: 0;
		margin-left: 0;
		padding-top: 17px;
		padding-bottom: 16px;
		padding-left: $drawer_a_padding_left;
		&:hover,
		&.selected {
			color: $drawer_color-hover;
		}
	}

	.drawer {
		&__a {
			@include hide-text;

			$gnavi-labels: clinic, service, colonoscopy, gastroscopy, access, recruit;
			$gnavi-text1s: $menu-text-1, $menu-text-2, $menu-text-3, $menu-text-4, $menu-text-5, $menu-text-6;
			$gnavi-text-hovers: $menu-text-hover-1, $menu-text-hover-2, $menu-text-hover-3, $menu-text-hover-4, $menu-text-hover-5, $menu-text-hover-6;
			@for $i from 1 through length($gnavi-text1s) {
				$label: nth($gnavi-labels, $i);
				$text1: nth($gnavi-text1s, $i);
				$hover: nth($gnavi-text-hovers, $i);
				&-#{$label} {
					@include icon-sprite2($text1);
					&:hover {
						&::before {
							@include sprite2($hover);
						}
					}
				}
			}
			&-home {
				@include icon-sprite2($menu-text-home);
				&:hover {
					&::before {
						@include sprite2($menu-text-home-hover);
					}
				}
			}

			&::before {
				left: $drawer_a_padding_left;
				transform: scale(.9) translate(-11px, -11px);
			}
		}
		&__foot {
			text-align: center;
			padding-top: 20px;
			&::after {
				display: none;
			}

			.form_btn_wrapper {
				position: relative;
				top: 0;
				left: 0;
				height: 30px;
			}
			.form_btn {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}

	}
	&.drawer-show {
		visibility: visible;
	}
	&.mm-menu.mm-offcanvas {
		max-width: $drawer_width;
	}
	.mm-listview > li.mm-selected {
		> a:not(.mm-next),
		> span {
			background-color: transparent;
		}
	}
	// .mm-navbar {
	// 	&-top {
	// 		height: $drawer_li_top_height;
	// 		border-bottom: 0 none;
	// 		padding-top: 0;
	// 		padding-bottom: 0;
	// 	}
	// }
	.mm-panels {
		background-color: $drawer_background-color;
		padding-top: 0;

		> .mm-panel.mm-hasnavbar {
			padding-top: 0;
		}
		> .mm-panel::before {
			height: 0;
		}
		.mm-navbar {
			padding-top: 1em;
			border-bottom: $drawer_li_border_width solid $drawer_li_border_color;
			margin-right: 0;
			box-sizing: border-box;
			width: 100%;
			height: $drawer_li_top_height;
			position: absolute;
			top: 0;
			left: 0;

			.mm-prev {
				top: 0;
				height: $drawer_li_top_height - $drawer_li_border_width;
				&::before {
					top: 0;
				}
			}
			.mm-title {
				// font-weight: bold;
				font-size: $fontsize16;
				color: $drawer_color;
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				height: $drawer_li_top_height - $drawer_li_border_width;
				box-sizing: border-box;
				padding-left: 0;
			}
		}
		.mm-navbar + .mm-listview {
			margin-top: $drawer_li_top_height;
		}
	}
	// .mm-prev::before {
	// 	top: auto;
	// }
	#mm-1 {
		.mm-navbar + .mm-listview {
			margin-top: 0;
		}
		> .mm-navbar {
			display: none;
		}
	}
	.mm-listview {
		margin-bottom: 0;
	}
	.mm-panels > .mm-panel::after {
		height: 0;
	}

	.toggle,
	.gnavi__cap {
		display: none;
	}

	.mm-listview .mm-vertical .mm-panel,
	.mm-vertical .mm-listview .mm-panel {
		padding-top: 25px;
		padding-bottom: 25px;
		position: relative;
		top: 0;
		left: 0;
		padding-left: 0;

		&::before {
			// @include sprite2($menu-drawer-about-top);
			content: '';
			display: block;
			position: absolute;
			top: -13px;
			left: 50%;
			transform: translateX(-50%);
		}

		.a1 {
			display: block;
			text-align: center;
			color: #fff;
			padding: 0;
			padding-bottom: 10px;
			height: 40px;
			.text {
				display: inline-block;
				font-size: 14px;
				height: 30px;
				line-height: 30px;
				border-bottom: 2px solid transparent;
				position: relative;
				top: -10px;
				left: 0;
				font-weight: bold;
			}
			&:hover .text,
			&.active .text {
				border-bottom-color: #fff;
			}
			&:hover {
				opacity: 1;
			}
		}
		.li1 {
			min-height: initial;
			min-height: auto;
			&::after {
				display: none;
			}
		}
		.mm-next {
			display: none;
		}
	}
}

.mm-opening #mm-blocker {
	background: #000 none repeat scroll 0 0;
	transition: background 1s ease 0.2s;
	opacity: 0.2;
	z-index: 10001;
}
html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-1 * $drawer_width, 0);
    -moz-transform: translate(-1 * $drawer_width, 0);
    -ms-transform: translate(-1 * $drawer_width, 0);
    -o-transform: translate(-1 * $drawer_width, 0);
    transform: translate(-1 * $drawer_width, 0);
}
