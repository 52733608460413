@charset "utf-8";

@mixin icon($url, $width, $height, $top: 0) {
	content: '';
	display: inline-block;
	background: url( $url ) 0 center no-repeat transparent;
	background-size: $width $height;
	width: $width;
	height: $height;
	position: relative;
	top: $top;
	left: 0;
}
@mixin icon_block($url, $width, $height) {
	content: '';
	display: block;
	background: url($url) 0 0 no-repeat transparent;
	background-size: $width $height;
	width: $width;
	height: $height;
}

@mixin bg($url, $width, $height) {
	background: url($url) 0 0 no-repeat transparent;
	background-size: $width $height;
	width: $width;
	height: $height;
}

// @font-face {
// 	font-family: 'San Francisco';
// 	font-weight: 400;
// 	src: url("../font/sanfranciscodisplay-regular-webfont.woff");
// }

@mixin fot_l($size: 'L') {
	font-family: "FOT-UD角ゴ_ラージ Pr6 #{$size}", "UDKakugo_LargePr6-#{$size}", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin fot_s_l {
	font-family: "FOT-UD角ゴ_スモール Pr6 L", "UDKakugo_SmallPr6-L", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin fot_s_r {
	font-family: "FOT-UD角ゴ_スモール Pr6 R", "UDKakugo_SmallPr6-R", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin fot_s_m {
	font-family: "FOT-UD角ゴ_スモール Pr6 M", "UDKakugo_SmallPr6-M", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin fot_s_db {
	font-family: "FOT-UD角ゴ_スモール Pr6 DB", "UDKakugo_SmallPr6-DB", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin fot_s_b {
	font-family: "FOT-UD角ゴ_スモール Pr6 B", "UDKakugo_SmallPr6-B", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}

@mixin fot_cezanne_m {
	font-family: "FOT-セザンヌ Pro M", "CezannePro-M", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin fot_cezanne_db {
	font-family: "FOT-セザンヌ Pro DB", "CezannePro-DB", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin fot_cezanne_b {
	font-family: "FOT-セザンヌ Pro B", "CezannePro-B", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}


@mixin rodin_b {
	font-family: "FOT-ロダン Pro B", "RodinPro-B", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin rodin_db {
	font-family: "FOT-ロダン Pro DB", "RodinPro-DB", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin rodin_m {
	font-family: "FOT-ロダン Pro M", "RodinPro-M", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}
@mixin rodin_l {
	font-family: "FOT-ロダン Pro L", "RodinPro-L", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}

@mixin default_font-family {
	font-family: "Roboto", sans-serif;
	// font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: normal;
}

@mixin century_gothic-family {
	font-family: "Century Gothic", Verdana, Arial, "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
@mixin yu_font-family {
	font-family: "游ゴシック", "YuGothic", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
@mixin sanfrancisco_font-family {
	font-family: "San Francisco", "游ゴシック", "YuGothic", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
@mixin mincho_yu {
	font-family: "YuMincho", "游明朝", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
	font-weight: normal;
}
@mixin mincho_m {
	font-family: "FOT-筑紫明朝 Pr5 M", "TsukuMinPr5-M", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
	font-weight: normal;
}
@mixin mincho_b {
	font-family: "FOT-筑紫明朝 Pr5 B", "TsukuMinPr5-B", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
	font-weight: bold;
}
@mixin mincho_e {
	font-family: "FOT-筑紫明朝 Pr5 E", "TsukuMinPr5-E", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
	font-weight: lighter;
}
@mixin mincho_h {
	font-family: "FOT-筑紫明朝 Pr5 H", "TsukuMinPr5-HV", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
	font-weight: bold;
}
@mixin arial_font-family {
	font-family: "Arial", "Roboto", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-weight: bold;
}

@mixin button_hover {
	color: $button_color_hover;
	background-color: $button_color_bg_hover;
	transition: background-color .2s ease-out;
}

@mixin vertical-align-middle($is_center: false) {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	@if($is_center == true) {
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
}

@mixin columns_fix($width, $float: left) {
	width: $width;
	position: relative;
	top: 0;
	left: 0;
	z-index: 2;
	float: $float;
}
@mixin columns_flex($width, $float: left, $padding: 0) {
	width: 100%;
	box-sizing: border-box;
	float: $float;
	@if $float == left {
		padding-right: $width + $padding;
		margin-right: $width * -1;
	} @else if $float == right {
		padding-left: $width + $padding;
		margin-left: $width * -1;
	}
}

@mixin columns($column, $padding: 20px) {
	@include clearfix;
	$width: (100% / $column);
	> * {
		width: $width;
		box-sizing: border-box;
		float: left;
	}
	@for $i from 1 through ($column) {
		$ii: $column - $i;
		@if ($column != $i) {
			> *:nth-child(#{$column}n + #{$i}) {
				@if ($i == 1) {
					clear: left;
					padding-right: $padding / $column * $ii;
				} @else {
					padding-left: $padding / $column * ($i - 1);
					padding-right: $padding / $column * $ii;
				}
			}
		} @else {
			> *:nth-child(#{$column}n) {
				padding-left: $padding / $column * ($i - 1);
			}
		}
	}
}


/* simple clearfix */
%clearfix {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}
@mixin clearfix {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}


@mixin on_opacity {
	&:hover {
		text-decoration: none;
		opacity: 0.7;
		transition: 0.2s opacity;
	}
}

/**************************
@mixin for sprite
*/

@mixin sprite2-width($sprite) {
	width: nth($sprite, 5) / 2;
}

@mixin sprite2-height($sprite) {
	height: nth($sprite, 6) / 2;
}

@mixin sprite2-position($sprite) {
	$sprite-offset-x: nth($sprite, 3) / 2;
	$sprite-offset-y: nth($sprite, 4) / 2;
	background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite2-size($sprite) {
	background-size: nth($sprite, 7) / 2 nth($sprite, 8) / 2;
}

@mixin sprite2($sprite) {
	@include sprite-image($sprite);
	@include sprite2-position($sprite);
	@include sprite2-width($sprite);
	@include sprite2-height($sprite);
	@include sprite2-size($sprite);
}

@mixin icon-sprite2-inline($sprite, $top: 'middle', $before: 'before', $is_block: false, $margin-right: .5em) {
	@include icon-sprite2($sprite, $top, $before, $is_block, $margin-right)
}

@mixin icon-sprite2($sprite, $top: 'middle', $before: 'before', $is_block: true, $margin-right: .5em) {
	position: relative;
	left: 0;
	@if ($is_block == true) {
		&::#{$before} {
			@include sprite2($sprite);
			content: '';
			display: block;
			position: absolute;
			left: 0;
			@if $top == 'middle' {
				top: 50%;
				transform: translateY(-50%);
			} @else {
				top: $top;
			}
		}
	} @else {
		&::#{$before} {
			@include sprite2($sprite);
			content: '';
			display: inline-block;
			@if $before == before {
				margin-right: $margin-right;
			} @else {
				margin-left: $margin-right;
			}
			@if $top != 'middle' {
				position: relative;
				top: $top;
				left: 0;
			}
		}
	}
}

// @mixin replace-sprite2($sprites, $image_height, $width: auto, $area_height: $image_height, $is_center: false, $before: before) {
// 	@include hide-text;
// 	display: block;
// 	height: $area_height;
// 	box-sizing: border-box;
// 	width: $width;
// 	&::#{$before} {
// 		@include sprite2($sprites);
// 		$image_height: $image_height;
// 		$mt: round(($area_height - $image_height) / 2);
// 		$mb: $area_height - $image_height - $mt;
// 		content: '';
// 		display: block;
// 		margin-top: $mt;
// 		margin-bottom: $mb;
// 		// height: $image_height;

// 		@if $is_center == true {
// 			position: absolute;
// 			top: 0;
// 			left: 50%;
// 			transform: translateX(-50%);
// 		}
// 	}
// }

@mixin default_image {
	// border-radius: $border_radius;
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
}

@mixin default_scroll_anime($delay: 0s) {
	transition: all 1.4s $easeOutExpo $delay;
	&.init {
		opacity: 0;
		transform: translateY(-5%);
	}
}

@mixin cancel() {
	padding: 0;
	margin: 0;
	list-style: none;
	width: inherit;
	height: inherit;
	border: 0 none;
	color: inherit;
	vertical-align: baseline;
	text-align: inherit;
	font-size: inherit;
	font-weight: normal;
	line-height: inherit;
	overflow: visible;
	border-radius: 0;
	position: static;
	left: inherit;
	left: auto;
	top: inherit;
	top: auto;
	clear: inherit;
	content: normal;
	min-height: inherit;
	min-height: auto;
	max-height: inherit;
	max-height: auto;
	float: none;
	transform: none;
	background: none center center / center center repeat-none transparent;
	box-sizing: content-box;
}

@mixin cancel_all() {
	@include cancel;
	&::before,
	&::after {
		@include cancel;
	}
}

@mixin cancel_list() {
	.main_content & {
		padding-left: 0;
		li {
			margin-bottom: 0;
			&::before {
				display: none;
			}
		}
	}
}

@mixin width_100vw {
	width: auto;
	max-width: inherit;
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
}

@mixin pc_wide {
	margin-left: -1 * $pc_padding;
	margin-right: -1 * $pc_padding;
}

